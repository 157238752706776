var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"max-width":"800px"},attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.deltakerFelter,"items":_vm.deltakere,"no-data-text":"Ingen deltakere.","items-per-page":-1,"must-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header.rediger",fn:function(){return [_c('a',{domProps:{"textContent":_vm._s(
                        _vm.type == 'SORGGRUPPE' ? (_vm.visAvmeldte ? 'Skjul meldt frafall' : 'Vis meldt frafall') : _vm.visAvmeldte ? 'Skjul avmeldte' : 'Vis avmeldte'
                    )},on:{"click":_vm.toggleParticipants}})]},proxy:true},{key:"item.deltatt",fn:function(ref){
                    var item = ref.item;
return [(item.person.fulltNavn && !item.fjernet)?_c('v-checkbox',{on:{"click":function($event){return _vm.participated(item, item.deltatt)}},model:{value:(item.deltatt),callback:function ($$v) {_vm.$set(item, "deltatt", $$v)},expression:"item.deltatt"}}):_vm._e()]}},{key:"item.fulltNavn",fn:function(ref){
                    var item = ref.item;
return [_c('span',{style:({ textDecoration: item.fjernet ? 'line-through' : 'none' }),domProps:{"innerHTML":_vm._s(item.person.fulltNavn || 'Slettet bruker')}})]}},{key:"item.lagtTil",fn:function(ref){
                    var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(item.lagtTil.format('DD.MM.YYYY'))}})]}},{key:"item.rediger",fn:function(ref){
                    var item = ref.item;
return [(item.fjernet)?_c('span',{domProps:{"innerHTML":_vm._s(item.fjernet.format('DD.MM.YYYY'))}}):_c('div',{staticClass:"d-flex justify-center"},[(!item.fjernet)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeParticipant(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}},{key:"foot",fn:function(){return [(_vm.alleDeltakere.length)?_c('tfoot',[_c('tr',[_c('td',{staticStyle:{"height":"auto"},attrs:{"colspan":"4"}},[_c('div',{staticClass:"pt-4 d-flex align-center"},[_c('v-autocomplete',{attrs:{"label":"Velg deltaker","items":_vm.alleDeltakere,"item-text":"fulltNavn","item-value":"id","no-data-text":"Ingen flere deltakere","hide-details":""},model:{value:(_vm.nyDeltaker),callback:function ($$v) {_vm.nyDeltaker=$$v},expression:"nyDeltaker"}}),_c('v-btn',{staticClass:"mt-4 ml-2",attrs:{"outlined":""},on:{"click":_vm.addParticipant}},[_vm._v(" Legg til ")])],1)])])]):_vm._e()]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }